import React from 'react';
import { IonBadge } from '@ionic/react';

interface shiftCountProps {
  count: number;
  total: number;
}

const ShiftCount: React.FC<shiftCountProps> = (props) => {
  return (
    <IonBadge
      color={
        props.count === props.total
          ? 'success'
          : props.count > props.total / 2
          ? 'warning'
          : 'danger'
      }
    >
      {props.count} / {props.total}
    </IonBadge>
  );
};

export default ShiftCount;
