import { configureStore } from '@reduxjs/toolkit';
import authReducer from './redux/auth.redux';
import projectsReducer from './redux/projects.redux';

export default configureStore({
  reducer: {
    auth: authReducer,
    projects: projectsReducer,
  },
});
