import React from 'react';
import { IonItem, IonLabel, IonList, IonSkeletonText } from '@ionic/react';

interface skeletonList {
  list: number;
}

const ListSkeleton: React.FC<skeletonList> = (props) => {
  const items = [];

  for (let i = 0; i < props.list; i++) {
    items.push(
      <IonItem key={i}>
        <IonLabel>
          <h3>
            <IonSkeletonText animated style={{ width: '50%' }} />
          </h3>
          <p>
            <IonSkeletonText animated style={{ width: '80%' }} />
          </p>
          <p>
            <IonSkeletonText animated style={{ width: '60%' }} />
          </p>
        </IonLabel>
      </IonItem>
    );
  }

  return <IonList>{items}</IonList>;
};

export default ListSkeleton;
