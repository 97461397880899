import React, { useEffect, useState } from 'react';
import { IonIcon, IonLabel, IonToggle } from '@ionic/react';
import { moon, sunnyOutline } from 'ionicons/icons';

const DarkThemeToggle: React.FC = () => {
  const [checkedDarkTheme, setCheckedDarkTheme] = useState<boolean>(false);

  useEffect(() => {
    const currentTheme = localStorage.getItem('theme');
    if (currentTheme === null) {
      localStorage.setItem('theme', 'light');
    } else {
      if (currentTheme === 'dark') {
        setDarkTheme();
      } else {
        setLightTheme();
      }
    }
  }, []);

  function toggleDarkModeHandler(e: any) {
    if (e.detail.checked) {
      setDarkTheme();
    } else {
      setLightTheme();
    }
  }

  function setDarkTheme() {
    localStorage.setItem('theme', 'dark');
    document.body.classList.add('dark');
    setCheckedDarkTheme(true);
  }

  function setLightTheme() {
    localStorage.setItem('theme', 'light');
    document.body.classList.remove('dark');
    setCheckedDarkTheme(false);
  }

  return (
    <>
      <IonIcon slot="start" icon={checkedDarkTheme ? moon : sunnyOutline} />
      <IonLabel>Dark Mode</IonLabel>
      <IonToggle
        slot="end"
        name="darkMode"
        checked={checkedDarkTheme}
        onIonChange={(e) => toggleDarkModeHandler(e)}
      />
    </>
  );
};

export default DarkThemeToggle;
