import React, { useEffect, useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonIcon,
  IonSpinner,
} from '@ionic/react';
import { get } from '../../services/auth.services';
import { useHistory } from 'react-router-dom';
import { logOutOutline } from 'ionicons/icons';
import { useDispatch } from 'react-redux';
import { setAuthenticated } from '../../redux/auth.redux';
import { setCurrentProject } from '../../redux/projects.redux';
import './settings.css';
import DarkThemeToggle from '../../components/DarkThemeToggle/DarkThemeToggle';

const Settings: React.FC = () => {
  const history = useHistory();
  const [project, setProject] = useState<number>();
  const [projectsList, setProjectsList] = useState<any[]>([]);
  const [showLoading, setShowLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const projectSelected = localStorage.getItem('project');
    setShowLoading(true);
    if (projectsList.length === 0) {
      get('/self/projects', { limit: 1000, order: 'project.name:ASC' })
        .then((response) => {
          setProjectsList(response.data);
          setShowLoading(false);
          if (projectSelected && projectSelected !== '') {
            setProject(parseInt(projectSelected));
          }
        })
        .catch((error) => {
          setShowLoading(false);
          if (error.status === 401) {
            dispatch(setAuthenticated(false));
          }
        });
    }
    // eslint-disable-next-line
  }, []);

  function handleChangeProject(project: string) {
    localStorage.setItem('project', project);
    setProject(parseInt(project));
  }

  function handleLogout() {
    const theme = localStorage.getItem('theme');
    localStorage.clear();
    if (theme) {
      localStorage.setItem('theme', theme);
    }
    dispatch(setAuthenticated(false));
    dispatch(setCurrentProject(null));
    history.push('/');
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle class="ion-text-center">Configuration</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
        <div className={'setting-container'}>
          <div>
            <IonItem>
              <IonLabel>Événement</IonLabel>

              {showLoading && <IonSpinner name="dots" />}
              <IonSelect
                value={project}
                okText="Ok"
                cancelText="Cancel"
                onIonChange={(e) => handleChangeProject(e.detail.value)}
                disabled={showLoading}
              >
                {projectsList.map((data) => {
                  return (
                    <IonSelectOption
                      key={data && data.project ? data.project.id : ''}
                      value={data && data.project ? data.project.id : ''}
                    >
                      {data && data.project ? data.project.name : ''}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            </IonItem>
            <IonItem>
              <DarkThemeToggle />
            </IonItem>
          </div>
          <IonButton
            expand="block"
            color="danger"
            onClick={() => handleLogout()}
          >
            <IonIcon
              slot="start"
              icon={logOutOutline}
              className={'logOutIcon'}
            />
            SE DECONNECTER
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
