import React from 'react';
import { IonSpinner } from '@ionic/react';
import './Loading.css';

interface LoadingProps {
  isLoading: boolean;
}

const Loading: React.FC<LoadingProps> = (props) => {
  return (
    <>
      {props.isLoading && (
        <div className={'shift-loading'}>
          <IonSpinner name="crescent" />
        </div>
      )}
    </>
  );
};

export default Loading;
