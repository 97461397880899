export function getLongWeekDay(date: string, language: string) {
  return new Date(date).toLocaleString(language, {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  });
}

export function getShortTime(date: string, duration: number, language: string) {
  const dateObj = new Date(date);
  const timeStart = dateObj.toLocaleTimeString(language, {
    hour: '2-digit',
    minute: '2-digit',
  });
  const timeEnd = new Date(
    dateObj.getTime() + duration * 60000
  ).toLocaleTimeString(language, {
    hour: '2-digit',
    minute: '2-digit',
  });

  return timeStart + ' - ' + timeEnd;
}
