import React, { Fragment, useEffect, useState } from 'react';
import {
  IonButtons,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonButton,
  IonThumbnail,
  IonItemDivider,
  IonAccordionGroup,
  IonAccordion,
  IonText,
} from '@ionic/react';

import './volunteer.css';
import { RouteComponentProps } from 'react-router';
import { checkmarkCircle, callOutline, personOutline } from 'ionicons/icons';
import { get, update } from '../../services/auth.services';
import { setAuthenticated } from '../../redux/auth.redux';
import { useDispatch } from 'react-redux';
import { formatVolunteerShifts } from '../../services/formatShifts';
import CheckVolunteers from '../../components/checkVolunteers/checkVolunteers';
import Loading from '../../components/Loading/Loading';

type VolunteerDetailPageProps = RouteComponentProps;

interface userProfileInterface {
  email: string;
  emailConfirmed: boolean;
  fullName: string;
  gender: string;
  id: number;
  phonenumber: string;
  lastName: string;
  firstName: string;
}

const VolunteerDetail: React.FC<VolunteerDetailPageProps> = (props) => {
  const currentState: any = props.location.state;
  const [userProfile, setUserProfile] = useState<userProfileInterface | null>(
    null
  );
  const [userShiftsList, setUserShiftsList] = useState<any[]>([]);
  const [userCards, setUserCards] = useState<any[]>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const getVolunteerDetail = (volunteer: any) => {
    get('/attendees/' + volunteer.id + '/volunteerposts')
      .then((response) => {
        formatShift(response.data);
      })
      .catch((error) => {
        if (error.status === 401) {
          dispatch(setAuthenticated(false));
        }
      });
  };

  const getVolunteerCards = (volunteer: any) => {
    const project = localStorage.getItem('project');
    //https://live.byemisys.com/api/v2019-01/users/219469/wallets?project_id=489
    get(
      '/users/' +
        volunteer.userProfile?.user?.id +
        '/wallets?project_id=' +
        project
    )
      .then((response) => {
        formatCards(response.data);
      })
      .catch((error) => {
        if (error.status === 401) {
          dispatch(setAuthenticated(false));
        }
      });
  };

  useEffect(() => {
    if (currentState && currentState.volunteer) {
      setUserProfile(currentState.volunteer.userProfile);
      getVolunteerDetail(currentState.volunteer);
      getVolunteerCards(currentState.volunteer);
    } else {
      props.history.push('/volunteer');
    }
    // eslint-disable-next-line
  }, [currentState?.volunteer]);

  function formatShift(shiftsList: any) {
    const formatedList = formatVolunteerShifts(shiftsList);
    setUserShiftsList(formatedList);
  }

  function formatCards(cards: any) {
    const newCards: any = {};

    cards.forEach((card: any) => {
      const cardLabel = card.cardOwner.card.label;
      const cardObject = {
        amount: card.amount,
        walletName: card.wallet.name,
        unitSymbole: card.wallet.unitSymbole,
      };
      if (Object.prototype.hasOwnProperty.call(newCards, cardLabel)) {
        newCards[cardLabel].push(cardObject);
      } else {
        newCards[cardLabel] = [cardObject];
      }
    });

    return setUserCards(newCards);
  }

  function handleChangePresence(id: number, attended: boolean) {
    setShowLoading(true);
    update('/volunteerposts/' + id, { attended: attended })
      .then(() => {
        getVolunteerDetail(currentState.volunteer);
      })
      .catch((error) => {
        if (error.status === 401) {
          dispatch(setAuthenticated(false));
        }
      })
      .finally(() => {
        setShowLoading(false);
      });
  }

  function renderShiftName(shift: any) {
    let shiftName = '';
    if (shift.postManpower.place) {
      shiftName = shift.postManpower.place.name;
    }
    if (shift.postManpower.post) {
      shiftName = shift.postManpower.post.name;
    }

    if (shift.postManpower.place && shift.postManpower.post) {
      shiftName =
        shift.postManpower.post.name + ' - ' + shift.postManpower.place.name;
    }

    return shiftName;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="volunteer" />
          </IonButtons>
          <IonTitle class="ion-text-center">{userProfile?.fullName}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonThumbnail
        className={`user-image-banner ${showLoading && 'loading'}`}
        color={'medium'}
      >
        <IonIcon icon={personOutline} className={'user-image-icon'} />
      </IonThumbnail>

      <IonContent
        className={`shift-volunteerDetail ${showLoading && 'loading'}`}
      >
        <IonList>
          <IonItem>
            <IonLabel>
              <b>Nom</b>
              <p>{userProfile?.lastName}</p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <b>Prenom</b>
              <p>{userProfile?.firstName}</p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <b>Email</b>
              <p>{userProfile?.email}</p>
            </IonLabel>
            {userProfile && userProfile.emailConfirmed && (
              <IonIcon icon={checkmarkCircle} color={'success'} />
            )}
          </IonItem>
          <IonItem>
            <IonLabel>
              <b>Numéro de téléphone</b>
              <p>{userProfile?.phonenumber}</p>
            </IonLabel>
            {userProfile?.phonenumber && (
              <IonButton
                href={'tel:' + userProfile?.phonenumber}
                disabled={userProfile?.phonenumber === ''}
                fill={'clear'}
              >
                <IonIcon icon={callOutline} color={'medium'} />
              </IonButton>
            )}
          </IonItem>
          {Object.keys(userCards).length > 0 && (
            <>
              <IonText className="user-shift-title">
                <h2>Support cashless</h2>
              </IonText>
              <IonAccordionGroup>
                {Object.keys(userCards).map((card: any, i: number) => (
                  <IonAccordion key={i} value={card}>
                    <IonItem slot="header">
                      <IonLabel>{card}</IonLabel>
                    </IonItem>
                    <div slot="content">
                      <IonList>
                        {userCards[card].map((info: any, j: number) => (
                          <IonItem key={j}>
                            <IonLabel>
                              <p>{info.walletName}</p>
                            </IonLabel>

                            <IonLabel slot="end" className={'card-amount'}>
                              {info.amount}
                              <span>{info.unitSymbole}</span>
                            </IonLabel>
                          </IonItem>
                        ))}
                      </IonList>
                    </div>
                  </IonAccordion>
                ))}
              </IonAccordionGroup>
            </>
          )}
          {Object.keys(userShiftsList).length > 0 && (
            <>
              <IonText className="user-shift-title">
                <h2>Shifts</h2>
              </IonText>
              {Object.keys(userShiftsList).map((date: any, index: number) => (
                <Fragment key={index}>
                  <IonItemDivider color="light">
                    <IonLabel>{date}</IonLabel>
                  </IonItemDivider>

                  <IonAccordionGroup>
                    {Object.keys(userShiftsList[date]).map(
                      (time: any, indexJ: number) => (
                        <IonAccordion key={indexJ} value={time}>
                          <IonItem slot="header">
                            <IonLabel>{time}</IonLabel>
                          </IonItem>
                          <div slot="content">
                            <IonList>
                              {userShiftsList[date][time].map(
                                (shift: any, indexK: number) => (
                                  <IonItem key={indexK}>
                                    <IonLabel>
                                      {renderShiftName(shift)}
                                    </IonLabel>

                                    <CheckVolunteers
                                      shift={shift}
                                      handleChange={handleChangePresence}
                                    />
                                  </IonItem>
                                )
                              )}
                            </IonList>
                          </div>
                        </IonAccordion>
                      )
                    )}
                  </IonAccordionGroup>
                </Fragment>
              ))}
            </>
          )}
        </IonList>
      </IonContent>
      <Loading isLoading={showLoading} />
    </IonPage>
  );
};

export default VolunteerDetail;
