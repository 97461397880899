import React, { useEffect, useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonList,
  IonLabel,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/react';
import './volunteer.css';
import { get } from '../../services/auth.services';
import EmptyPage from '../../components/EmptyPage/EmptyPage';
import SearchInput from '../../components/SearchInput/SearchInput';
import { RouteComponentProps } from 'react-router';
import ListSkeleton from '../../components/Skeleton/ListSkeleton';
import { setAuthenticated } from '../../redux/auth.redux';
import { useDispatch } from 'react-redux';

type VolunteerPageProps = RouteComponentProps;

const Volunteer: React.FC<VolunteerPageProps> = (props) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [volunteersList, setVolunteersList] = useState<any[]>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState<boolean>(false);
  const [volunteersTotal, setVolunteersTotal] = useState<number>(0);
  const dispatch = useDispatch();

  useEffect(() => {
    getVolunteers('', 1, null);
    // eslint-disable-next-line
  }, []);

  function handleSearch(value: string) {
    setSearchValue(value);
    getVolunteers(value, 1, null);
  }

  function getVolunteers(value: string, page: number, isEvent: any) {
    const project = localStorage.getItem('project');

    if (!isEvent) {
      setInfiniteDisabled(false);
      setShowLoading(true);
      setPage(1);
    }
    get(
      '/projects/' +
        project +
        '/attendees?isVolunteer=true&limit=250&page=' +
        page +
        '&fullName|contain=' +
        encodeURIComponent(deleteSpacesAndQuotes(value))
    )
      .then((response) => {
        if (isEvent) {
          isEvent.target.complete();
          setVolunteersList([...volunteersList, ...response.data]);
        } else {
          setVolunteersList(response.data);
        }

        setVolunteersTotal(response.meta.object_count);
      })
      .catch((error) => {
        if (error.status === 401) {
          dispatch(setAuthenticated(false));
        }
      })
      .finally(() => {
        setShowLoading(false);
      });
  }

  function deleteSpacesAndQuotes(value: string) {
    return value.replace(/['"]+/g, '').replace(/\s+/g, '');
  }

  function showDetail(volunteer: any) {
    props.history.push('volunteer-detail', { volunteer });
  }

  function loadInfiniteScroll(event: any) {
    getVolunteers(searchValue, page + 1, event);
    setPage(page + 1);

    if (volunteersList.length + 1 >= volunteersTotal) {
      setInfiniteDisabled(true);
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle class="ion-text-center">Bénévoles</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <SearchInput
            delay={500}
            onChange={(e) => handleSearch(e.detail.value!)}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
        {showLoading ? (
          <ListSkeleton list={6} />
        ) : volunteersList.length > 0 ? (
          <>
            <IonList>
              {volunteersList.map((volunteer, index: number) => (
                <IonItem
                  key={index}
                  onClick={() => showDetail(volunteer)}
                  button
                >
                  <IonLabel>
                    <h2>{volunteer.userProfile.fullName}</h2>
                    <h3>{volunteer.userProfile.email}</h3>
                  </IonLabel>
                </IonItem>
              ))}
            </IonList>
            <IonInfiniteScroll
              onIonInfinite={loadInfiniteScroll}
              disabled={isInfiniteDisabled}
            >
              <IonInfiniteScrollContent loadingSpinner="bubbles" />
            </IonInfiniteScroll>
          </>
        ) : (
          <EmptyPage message={'Aucun resultat'} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Volunteer;
