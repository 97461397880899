import React from 'react';
import './EmptyPage.css';

interface emptyPageProps {
  message: string;
}

const EmptyPage: React.FC<emptyPageProps> = (props) => {
  return <div className={'empty-page-container'}>{props.message}</div>;
};

export default EmptyPage;
