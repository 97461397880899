import React from 'react';
import { IonSearchbar } from '@ionic/react';

interface SearchInputProps {
  delay?: number;
  onChange: (e: any) => void;
}

const SearchInput: React.FC<SearchInputProps> = (props) => {
  return (
    <>
      <IonSearchbar
        debounce={props.delay ? props.delay : 0}
        placeholder="RECHERCHE"
        onIonChange={props.onChange}
      />
    </>
  );
};

export default SearchInput;
