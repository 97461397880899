import React, { Fragment, useEffect, useState } from 'react';
import {
  IonButtons,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonContent,
  IonList,
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonLabel,
  useIonViewWillLeave,
  IonItemDivider,
} from '@ionic/react';

import './shift.css';
import { RouteComponentProps } from 'react-router';
import { get, update } from '../../services/auth.services';
import { setAuthenticated } from '../../redux/auth.redux';
import { useDispatch } from 'react-redux';
import ListSkeleton from '../../components/Skeleton/ListSkeleton';
import ShiftCount from '../../components/ShiftCount/ShiftCount';
import EmptyPage from '../../components/EmptyPage/EmptyPage';
import { formatVolunteerShifts } from '../../services/formatShifts';
import CheckVolunteers from '../../components/checkVolunteers/checkVolunteers';
import Loading from '../../components/Loading/Loading';

type ShiftDetailPageProps = RouteComponentProps;

interface shiftProps {
  attendee: any;
  userProfile: any;
  attended: boolean;
  fullName: string;
  id: number;
}

const ShiftDetail: React.FC<ShiftDetailPageProps> = (props) => {
  const [showPageLoading, setShowPageLoading] = useState(false);
  const [showUpdateLoading, setShowUpdateLoading] = useState(false);
  const [postsShiftsList, setPostsShiftsList] = useState<any[]>([]);
  const dispatch = useDispatch();
  const currentState: any = props.location.state;

  const getVolunteerByPlaces = (isGlobalLoading: boolean) => {
    if (isGlobalLoading) {
      setShowPageLoading(true);
    }

    get('/postplaces/' + currentState.details.id + '/volunteerposts?limit=1000')
      .then((response) => {
        formatShift(response.data);
      })
      .catch((error) => {
        if (error.status === 401) {
          dispatch(setAuthenticated(false));
        }
      })
      .finally(() => {
        setShowPageLoading(false);
        setShowUpdateLoading(false);
      });
  };

  const getVolunteerByPosts = (isGlobalLoading: boolean) => {
    if (isGlobalLoading) {
      setShowPageLoading(true);
    }

    get('/posts/' + currentState.details.id + '/volunteerposts?limit=1000')
      .then((response) => {
        formatShift(response.data);
      })
      .catch((error) => {
        if (error.status === 401) {
          dispatch(setAuthenticated(false));
        }
      })
      .finally(() => {
        setShowPageLoading(false);
        setShowUpdateLoading(false);
      });
  };

  useEffect(() => {
    if (currentState && currentState.currentCategory === 'places') {
      getVolunteerByPlaces(true);
    } else if (currentState && currentState.currentCategory === 'posts') {
      getVolunteerByPosts(true);
    } else {
      props.history.push('/shift');
    }
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  function formatOrderByName(list: any) {
    Object.keys(list).map((key) => {
      list[key] = list[key].sort(function (
        a: { attendee: any },
        b: { attendee: any }
      ) {
        return a.attendee?.userProfile?.lastName.localeCompare(
          b.attendee?.userProfile?.lastName
        );
      });
    });
    return list;
  }

  function formatShift(shiftsList: any) {
    const formatedList = formatVolunteerShifts(shiftsList);

    // eslint-disable-next-line
    Object.keys(formatedList).map((date: any) => {
      // formatedList[date] = formatOrderByName(formatedList[date]);
    });

    setPostsShiftsList(formatedList);
  }

  function handleChangePresence(id: number, attended: boolean) {
    setShowUpdateLoading(true);
    update('/volunteerposts/' + id, { attended: attended })
      .then(() => {
        if (currentState && currentState.currentCategory === 'places') {
          getVolunteerByPlaces(false);
        } else if (currentState && currentState.currentCategory === 'posts') {
          getVolunteerByPosts(false);
        }
      })
      .catch((error) => {
        if (error.status === 401) {
          dispatch(setAuthenticated(false));
        }
      });
  }

  useIonViewWillLeave(() => {
    setPostsShiftsList([]);
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="shift" />
          </IonButtons>
          <IonTitle class="ion-text-center">
            {currentState?.details?.name}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
        {showPageLoading ? (
          <ListSkeleton list={4} />
        ) : Object.keys(postsShiftsList).length > 0 ? (
          <IonList className={`shift-detail ${showUpdateLoading && 'loading'}`}>
            {Object.keys(postsShiftsList).map((date: any, index: number) => {
              return (
                <Fragment key={index}>
                  <IonItemDivider color="light">
                    <IonLabel>{date}</IonLabel>
                  </IonItemDivider>
                  <IonAccordionGroup>
                    {Object.keys(postsShiftsList[date]).map(
                      (time: any, indexJ: number) => {
                        let emptyShift = 0;
                        postsShiftsList[date][time].forEach(
                          (shift: shiftProps) => {
                            if (shift.attended !== null) {
                              emptyShift++;
                            }
                          }
                        );
                        return (
                          <IonAccordion
                            key={indexJ}
                            value={time}
                            disabled={showUpdateLoading}
                          >
                            <IonItem slot="header">
                              <IonLabel>{time}</IonLabel>
                              <ShiftCount
                                count={emptyShift}
                                total={postsShiftsList[date][time].length}
                              />
                            </IonItem>
                            <div slot="content">
                              <IonList>
                                {postsShiftsList[date][time].map(
                                  (shift: shiftProps, indexK: number) => (
                                    <IonItem key={indexK}>
                                      <IonLabel>
                                        {shift.attendee?.userProfile?.lastName}{' '}
                                        {shift.attendee?.userProfile?.firstName}
                                      </IonLabel>

                                      <CheckVolunteers
                                        shift={shift}
                                        handleChange={handleChangePresence}
                                      />
                                    </IonItem>
                                  )
                                )}
                              </IonList>
                            </div>
                          </IonAccordion>
                        );
                      }
                    )}
                  </IonAccordionGroup>
                </Fragment>
              );
            })}
          </IonList>
        ) : (
          <EmptyPage message={'aucun shift pour ici'} />
        )}
        <Loading isLoading={showUpdateLoading} />
      </IonContent>
    </IonPage>
  );
};

export default ShiftDetail;
