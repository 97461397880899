import React from 'react';
import { IonIcon } from '@ionic/react';
import { checkmarkOutline, closeOutline } from 'ionicons/icons';

import './checkVolunteers.css';

interface checkVolunteerProps {
  shift: any;
  handleChange: any;
}

const CheckVolunteers: React.FC<checkVolunteerProps> = (props) => {
  return (
    <>
      <IonIcon
        slot="end"
        onClick={() => props.handleChange(props.shift.id, true)}
        className={
          'acceptVolunteer' + (props.shift.attended === true ? ' active' : '')
        }
        icon={checkmarkOutline}
      />
      <IonIcon
        slot="end"
        onClick={() => props.handleChange(props.shift.id, false)}
        className={
          'rejectVolunteer' + (props.shift.attended === false ? ' active' : '')
        }
        icon={closeOutline}
      />
    </>
  );
};

export default CheckVolunteers;
