import { createSlice } from '@reduxjs/toolkit';

function getProjectSelected() {
  const project = localStorage.getItem('project');
  return project ? project : null;
}

export const projectsSlice = createSlice({
  name: 'projects',
  initialState: {
    currentProject: getProjectSelected(),
  },
  reducers: {
    setCurrentProject: (state, action) => {
      state.currentProject = action.payload;
    },
  },
});

export const { setCurrentProject } = projectsSlice.actions;

export default projectsSlice.reducer;
