import { createSlice } from '@reduxjs/toolkit';

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const isLogged = () => {
  const jwtToken = localStorage.getItem('token');
  if (jwtToken) {
    const decodedJwt = parseJwt(jwtToken);
    if (decodedJwt) {
      if (decodedJwt.exp * 1000 <= Date.now()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: isLogged(),
  },
  reducers: {
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
  },
});

export const { setAuthenticated } = authSlice.actions;

export default authSlice.reducer;
