import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {
  accessibilityOutline,
  cogOutline,
  calendarClearOutline,
} from 'ionicons/icons';
import Volunteer from './pages/Volunteer/volunteer';
import Shift from './pages/Shift/shift';
import Settings from './pages/Settings/settings';
import Login from './pages/Login/Login';
import ProjectChoice from './pages/ProjectChoice/ProjectChoice';
import { useSelector } from 'react-redux';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import ShiftDetail from './pages/Shift/ShiftDetail';
import VolunteerDetail from './pages/Volunteer/volunteerDetail';

setupIonicReact();

const App: React.FC = () => {
  const isAuthenticated = useSelector(
      // eslint-disable-next-line
    (state: any = {}) => state.auth.isAuthenticated
  );

  const currentProject = useSelector(
      // eslint-disable-next-line
    (state: any = {}) => state.projects.currentProject
  );

  useEffect(() => {
    const currentTheme = localStorage.getItem('theme');
    if (currentTheme === null) {
      localStorage.setItem('theme', 'light');
    } else {
      if (currentTheme === 'dark') {
        document.body.classList.add('dark');
      } else {
        document.body.classList.remove('dark');
      }
    }
  }, []);

  return (
    <IonApp>
      {isAuthenticated && currentProject ? (
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              <Switch>
                <Route exact path="/volunteer" component={Volunteer} />
                <Route
                  exact
                  path="/volunteer-detail"
                  component={VolunteerDetail}
                />
                <Route exact path="/shift" component={Shift} />
                <Route exact path="/shift-detail" component={ShiftDetail} />
                <Route exact path="/settings" component={Settings} />
                <Redirect from={'/project-choice'} to="/settings" exact />
              </Switch>
            </IonRouterOutlet>
            <IonTabBar id="toolbar-react" slot="bottom">
              <IonTabButton tab="volunteer" href="/volunteer">
                <IonIcon icon={accessibilityOutline} />
                <IonLabel>Bénévoles</IonLabel>
              </IonTabButton>
              <IonTabButton tab="shift" href="/shift">
                <IonIcon icon={calendarClearOutline} />
                <IonLabel>Shifts</IonLabel>
              </IonTabButton>
              <IonTabButton tab="settings" href="/settings">
                <IonIcon icon={cogOutline} />
                <IonLabel>Config</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      ) : (
        <IonReactRouter>

          <Route path="/login" component={Login} exact />
          <Route path="/project-choice" component={ProjectChoice} />
          <Redirect from="/" to="/login" exact />
        </IonReactRouter>
      )}
    </IonApp>
  );
};

export default App;
