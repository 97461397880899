export const httpService = {
  checkStatus,
  handleError,
  parseJSON,
};

function checkStatus(response: any) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  return response.json().then((json: any) =>
    Promise.reject({
      status: response.status,
      ok: false,
      statusText: response.statusText,
      body: json,
    })
  );
}

function parseJSON(response: any) {
  if (response.status === 204) {
    return { status: response.status };
  }
  return response.json();
}

function handleError(error: any) {
  error.response = {
    status: 0,
    statusText:
      'Cannot connect. Please make sure you are connected to internet.',
  };
  throw error;
}

interface ReduxPropsType {
  [key: string]: any;
  loading: boolean;
  error: boolean;
}

export const checkReduxResponse = (props: ReduxPropsType, attr: string) => {
  return props && props[attr] && !props.loading && !props.error;
};
