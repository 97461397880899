// import { Config } from '../config';
import { httpService } from './httpService';
import { env } from '../Env';

export const getToken = () => {
  return localStorage.getItem('token');
};

export const getLocale = () => {
  return localStorage.getItem('locale');
};

type MethodType = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
export const getHeaders = (method: MethodType, body?: unknown) => {
  return {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getToken(),
    },
    body: body ? JSON.stringify(body) : null,
  };
};

export const get = (url: string | URL, params?: any) => {
  return fetch(getUrl(url, params), getHeaders('GET'))
    .catch(httpService.handleError)
    .then(httpService.checkStatus)
    .then(httpService.parseJSON)
    .catch((error) => {
      throw error;
    });
};

export const post = (
  url: string | URL,
  body: any,
  params?: any,
  version?: string
) => {
  return fetch(getUrl(url, params, version), getHeaders('POST', body))
    .catch(httpService.handleError)
    .then(httpService.checkStatus)
    .then(httpService.parseJSON)
    .catch((error) => {
      throw error;
    });
};

export const update = (url: string | URL, body: any, params?: any) => {
  return fetch(getUrl(url, params), getHeaders('PUT', body))
    .catch(httpService.handleError)
    .then(httpService.checkStatus)
    .then(httpService.parseJSON)
    .catch((error) => {
      throw error;
    });
};

export const patch = (url: string | URL, body: any, params?: any) => {
  return fetch(getUrl(url, params), getHeaders('PATCH', body))
    .catch(httpService.handleError)
    .then(httpService.checkStatus)
    .then(httpService.parseJSON)
    .catch((error) => {
      throw error;
    });
};

export const del = (url: string | URL, body: any, params?: any) => {
  return fetch(getUrl(url, params), getHeaders('DELETE', body))
    .catch(httpService.handleError)
    .then(httpService.checkStatus)
    .then(httpService.parseJSON)
    .catch((error) => {
      throw error;
    });
};

export const getUrl = (
  path: string | URL,
  args: any,
  version = '/v2022-01'
) => {
  const _args = [];
  for (const key in args) {
    if (Object.prototype.hasOwnProperty.call(args, key)) {
      if (typeof args[key] === 'object' && !Array.isArray(args[key])) {
        _args.push(
          key +
            '|' +
            args[key].operation +
            '=' +
            encodeURIComponent(args[key].value)
        );
      } else if (typeof args[key] === 'object' && Array.isArray(args[key])) {
        args[key].map((data: any) => {
          _args.push(
            key + '|' + data.operation + '=' + encodeURIComponent(data.value)
          );
        });
      } else {
        _args.push(key + '=' + encodeURIComponent(args[key]));
      }
    }
  }

  if (_args && _args.length > 0) {
    path += '?' + _args.join('&');
  }

  return env.url + version + path;
};
