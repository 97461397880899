import React, { useEffect, useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { get } from '../../services/auth.services';
import { useDispatch } from 'react-redux';
import { setAuthenticated } from '../../redux/auth.redux';

import ListSkeleton from '../../components/Skeleton/ListSkeleton';
import EmptyPage from '../../components/EmptyPage/EmptyPage';
import SearchInput from '../../components/SearchInput/SearchInput';
import { RouteComponentProps } from 'react-router';
import './shift.css';

type ShiftPageProps = RouteComponentProps;

const Shift: React.FC<ShiftPageProps> = (props) => {
  const [showLoading, setShowLoading] = useState(false);
  const [currentCategory, setCurrentCategory] = useState('places');
  const [postsPlacesList, setPostsPlacesList] = useState<any[]>([]);
  const [searchPostsPlaces, setSearchPostsPlaces] = useState<any[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const project = localStorage.getItem('project');
    const currentCategoryShift = localStorage.getItem('currentCategory');
    setShowLoading(true);
    if (currentCategoryShift) {
      setCurrentCategory(currentCategoryShift);
    } else {
      setCurrentCategory('places');
    }
    if (currentCategoryShift && currentCategoryShift === 'posts') {
      get('/projects/' + project + '/posts?limit=1000')
        .then((response) => {
          setPostsPlacesList(response.data);
          setSearchPostsPlaces(response.data);
          setShowLoading(false);
        })
        .catch((error) => {
          setShowLoading(false);
          if (error.status === 401) {
            dispatch(setAuthenticated(false));
          }
        });
    } else {
      get('/projects/' + project + '/postplaces?order=name:ASC&limit=1000')
        .then((response) => {
          setPostsPlacesList(response.data);
          setSearchPostsPlaces(response.data);
          setShowLoading(false);
        })
        .catch((error) => {
          setShowLoading(false);
          if (error.status === 401) {
            dispatch(setAuthenticated(false));
          }
        });
    }
    // eslint-disable-next-line
  }, [currentCategory]);

  function handleSearch(value: string) {
    const searchResult: any = [];
    if (value !== '') {
      postsPlacesList.forEach((data) => {
        if (
          data.name &&
          data.name.toLowerCase().includes(value.toLowerCase())
        ) {
          searchResult.push(data);
        }
      });
      setSearchPostsPlaces(searchResult);
    } else {
      setSearchPostsPlaces(postsPlacesList);
    }
  }

  function showDetail(details: any) {
    props.history.push('shift-detail', { details, currentCategory });
  }

  function handleSelectCategory(value: string) {
    setCurrentCategory(value);
    localStorage.setItem('currentCategory', value);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonSelect
            onIonChange={(e) => handleSelectCategory(e.target.value!)}
            value={currentCategory}
            class="ion-text-center selector-category-shift"
            interface="popover"
            placeholder="Catégorie"
          >
            <IonSelectOption value="places">Lieux</IonSelectOption>
            <IonSelectOption value="posts">Fonctions</IonSelectOption>
          </IonSelect>
        </IonToolbar>
        <IonToolbar>
          <SearchInput onChange={(e) => handleSearch(e.detail.value!)} />
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
        {showLoading ? (
          <ListSkeleton list={6} />
        ) : searchPostsPlaces.length <= 0 ? (
          <EmptyPage message={'Aucun resultat'} />
        ) : (
          <IonList>
            {searchPostsPlaces.map((postPlace, index: number) => (
              <IonItem
                mode="ios"
                key={index}
                button
                onClick={() => showDetail(postPlace)}
              >
                {postPlace.name}
              </IonItem>
            ))}
          </IonList>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Shift;
