import { getLongWeekDay, getShortTime } from './dateFormater';
import { capitalizeFirstLetter } from './capitalize';

export function formatVolunteerShifts(shiftsList: []) {
  const formatedList: any = {};
  let dateDayHash: string;
  let dateTimeHash: string;
  shiftsList.map(
    (element: {
      attendee: any | null;
      attended: boolean;
      postManpower: {
        place: { name: string } | null;
        post: { name: string } | null;
        timeslot: {
          duration: number;
          startDay: any;
          startTime: any;
        };
      };
    }) => {
      if (
        element?.postManpower?.timeslot?.startDay &&
        element?.postManpower?.timeslot?.startTime
      ) {
        dateDayHash = capitalizeFirstLetter(
          getLongWeekDay(
            element.postManpower.timeslot.startDay,
            navigator.language
          )
        );

        dateTimeHash = getShortTime(
          element.postManpower.timeslot.startTime,
          element.postManpower.timeslot.duration,
          navigator.language
        );

        if (Object.prototype.hasOwnProperty.call(formatedList, dateDayHash)) {
          if (
            Object.prototype.hasOwnProperty.call(
              formatedList[dateDayHash],
              dateTimeHash
            )
          ) {
            formatedList[dateDayHash][dateTimeHash].push(element);
          } else {
            formatedList[dateDayHash][dateTimeHash] = [];
            formatedList[dateDayHash][dateTimeHash].push(element);
          }
        } else {
          formatedList[dateDayHash] = {};
          formatedList[dateDayHash][dateTimeHash] = [];
          formatedList[dateDayHash][dateTimeHash].push(element);
        }
      }
    }
  );

  return formatedList;
}
